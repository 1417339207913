@font-face {
    font-family: 'Aileron';
    src: url('Aileron-UltraLight.woff2') format('woff2'),
        url('Aileron-UltraLight.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Aileron';
    src: url('Aileron-Thin.woff2') format('woff2'),
        url('Aileron-Thin.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aileron';
    src: url('Aileron-Light.woff2') format('woff2'),
        url('Aileron-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Aileron';
    src: url('Aileron-Regular.woff2') format('woff2'),
        url('Aileron-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Aileron';
    src: url('Aileron-SemiBold.woff2') format('woff2'),
        url('Aileron-SemiBold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Aileron';
    src: url('Aileron-Bold.woff2') format('woff2'),
        url('Aileron-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aileron';
    src: url('Aileron-Heavy.woff2') format('woff2'),
        url('Aileron-Heavy.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Aileron';
    src: url('Aileron-Black.woff2') format('woff2'),
        url('Aileron-Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}








@font-face {
    font-family: 'Aileron';
    src: url('Aileron-ThinItalic.woff2') format('woff2'),
        url('Aileron-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}



@font-face {
    font-family: 'Aileron';
    src: url('Aileron-Italic.woff2') format('woff2'),
        url('Aileron-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Aileron';
    src: url('Aileron-BlackItalic.woff2') format('woff2'),
        url('Aileron-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}



@font-face {
    font-family: 'Aileron';
    src: url('Aileron-HeavyItalic.woff2') format('woff2'),
        url('Aileron-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aileron';
    src: url('Aileron-LightItalic.woff2') format('woff2'),
        url('Aileron-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}



@font-face {
    font-family: 'Aileron';
    src: url('Aileron-BoldItalic.woff2') format('woff2'),
        url('Aileron-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Aileron';
    src: url('Aileron-UltraLightItalic.woff2') format('woff2'),
        url('Aileron-UltraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aileron';
    src: url('Aileron-SemiBoldItalic.woff2') format('woff2'),
        url('Aileron-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}